<template>
  <main>
    <headerCustom :menuActive="true"/>
    <banner />
    <NavProduct />
    <note />
    <dream />
    <modules />
    <methodology />
    <benefits />
    <testimonials />
    <start />
    <plans />
    <about />
    <questions />
    <footerCustom />
    <WidgetWhatsApp phoneNumber="+573052328630"
                         companyName="WydeaLab"
                         textReply="Empezar a innovar en mi empresa."
                         companyLogo="https://wydealab.com/favicon.png"
    />
  </main>
</template>

<script>
import headerCustom from '@/components/basics/headerCustom.vue'
import banner from '@/components/landing/banner.vue'
import dream from '@/components/landing/dream.vue'
import modules from '@/components/landing/modules.vue'
import methodology from '@/components/landing/methodology.vue'
import benefits from '@/components/landing/benefits.vue'
import testimonials from '@/components/landing/testimonials.vue'
import start from '@/components/landing/start.vue'
import plans from '@/components/landing/plans.vue'
import about from '@/components/landing/about.vue'
import questions from '@/components/landing/questions.vue'
import footerCustom from '@/components/basics/footerCustom.vue'
import WidgetWhatsApp from '@/../packages/jumichica.vue.widget.whatsapp/src/components/Widget.vue'
import NavProduct from "@/components/basics/navProduct.vue";
import Note from "@/components/landing/note.vue";

export default {
  name: 'home',
  components: {
    Note,
    NavProduct,
    headerCustom,
    banner,
    dream,
    methodology,
    benefits,
    testimonials,
    start,
    plans,
    about,
    questions,
    footerCustom,
    modules,
    WidgetWhatsApp
  }
}
</script>