<template>
  <main>
    <headerCustom :menuActive="true"/>
    <banner />
    <navService />
    <note />
    <dream />
    <stats />
    <!--<modules />-->
    <how />
    <benefits />
    <testimonials />
    <start />
    <plans />
    <about />
    <questions />
    <footerCustom />
    <WidgetWhatsApp phoneNumber="+573052328630"
                    companyName="WydeaLab"
                    textReply="Empezar a innovar en mi empresa."
                    companyLogo="https://wydealab.com/favicon.png"
    />
  </main>
</template>

<script>
import headerCustom from '@/components/basics/headerCustom.vue'
import banner from '@/components/formulation/banner.vue'
import dream from '@/components/formulation/dream.vue'
import modules from '@/components/landing/modules.vue'
import how from '@/components/formulation/how.vue'
import benefits from '@/components/landing/benefits.vue'
import testimonials from '@/components/landing/testimonials.vue'
import start from '@/components/landing/start.vue'
import plans from '@/components/formulation/plans.vue'
import about from '@/components/landing/about.vue'
import questions from '@/components/landing/questions.vue'
import footerCustom from '@/components/basics/footerCustom.vue'
import WidgetWhatsApp from '@/../packages/jumichica.vue.widget.whatsapp/src/components/Widget.vue'
import Stats from '@/components/formulation/stats.vue';
import navService from '@/components/basics/navService.vue';
import note from '@/components/formulation/note.vue';

export default {
  name: 'formulation',
  components: {
    note,
    Stats,
    headerCustom,
    banner,
    dream,
    how,
    benefits,
    testimonials,
    start,
    plans,
    about,
    questions,
    footerCustom,
    modules,
    WidgetWhatsApp,
    navService
  },
  data() {
    return {
      title: 'Notificaciones de subsidios, oportunidades y muchos beneficios para empresas. Innova y crece.',
      content: 'Servicio de identificación de subvenciones, convocatorias, capacitaciones, becas y subsidios para empresas.',
      author: 'Wydea S.A.S',
      image: 'https://cdn.wydealab.com/wydealab/marketing/scrapidt_default.png'
    }
  },
  metaInfo() {
    return{
      title: this.title,
      titleTemplate: 'Scrapidt: %s',
      description: this.content,
      image: this.image,
      htmlAttrs: {
        lang: 'es-ES' // TODO: CHANG TO DINAMIC
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.content },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'og:title', content: this.title},
        { name: 'og:image', content: this.image},
        { name: 'og:description', content: this.description},
        { name: 'og:url', content: this.url}
      ]
    }
  },
  beforeMount() {
    this.metaInfo()
  }
}
</script>
